// import React, { useEffect, useRef } from 'react';
// import * as echarts from 'echarts';

// const CustomGaugeChart = ({ value, maxValue, unit }) => {
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const chart = echarts.init(chartRef.current);

//     const _panelImageURL = '/asset/logo/custom-gauge-panel.png';
//     const _animationDuration = 1000;
//     const _animationDurationUpdate = 1000;
//     const _animationEasingUpdate = 'quarticInOut';
//     const _outerRadius = 140;
//     const _innerRadius = 120;
//     const _pointerInnerRadius = 20;
//     const _insidePanelRadius = 100;

//     function renderItem(params, api) {
//       const valOnRadian = api.value(1);
//       const coords = api.coord([api.value(0), valOnRadian]);
//       const polarEndRadian = coords[3];
//       const imageStyle = {
//         image: _panelImageURL,
//         x: params.coordSys.cx - _outerRadius,
//         y: params.coordSys.cy - _outerRadius,
//         width: _outerRadius * 2,
//         height: _outerRadius * 2,
//       };

//       return {
//         type: 'group',
//         children: [
//           {
//             type: 'image',
//             style: imageStyle,
//             clipPath: {
//               type: 'sector',
//               shape: {
//                 cx: params.coordSys.cx,
//                 cy: params.coordSys.cy,
//                 r: _outerRadius,
//                 r0: _innerRadius,
//                 startAngle: 0,
//                 endAngle: -polarEndRadian,
//                 transition: 'endAngle',
//                 enterFrom: { endAngle: 0 },
//               },
//             },
//           },
//           {
//             type: 'image',
//             style: imageStyle,
//             clipPath: {
//               type: 'polygon',
//               shape: {
//                 points: makePionterPoints(params, polarEndRadian),
//               },
//               extra: {
//                 polarEndRadian: polarEndRadian,
//                 transition: 'polarEndRadian',
//                 enterFrom: { polarEndRadian: 0 },
//               },
//               during: function (apiDuring) {
//                 apiDuring.setShape(
//                   'points',
//                   makePionterPoints(params, apiDuring.getExtra('polarEndRadian')),
//                 );
//               },
//             },
//           },
//           {
//             type: 'circle',
//             shape: {
//               cx: params.coordSys.cx,
//               cy: params.coordSys.cy,
//               r: _insidePanelRadius,
//             },
//             style: {
//               fill: '#fff',
//               shadowBlur: 25,
//               shadowOffsetX: 0,
//               shadowOffsetY: 0,
//               shadowColor: 'rgba(76,107,167,0.4)',
//             },
//           },
//           {
//             type: 'text',
//             extra: {
//               valOnRadian: valOnRadian,
//               transition: 'valOnRadian',
//               enterFrom: { valOnRadian: 0 },
//             },
//             style: {
//               text: makeText(valOnRadian),
//               fontSize: 50,
//               fontWeight: 700,
//               x: params.coordSys.cx,
//               y: params.coordSys.cy,
//               fill: 'rgb(0,50,190)',
//               align: 'center',
//               verticalAlign: 'middle',
//               enterFrom: { opacity: 0 },
//             },
//             during: function (apiDuring) {
//               apiDuring.setStyle('text', makeText(apiDuring.getExtra('valOnRadian')));
//             },
//           },
//         ],
//       };
//     }

//     function convertToPolarPoint(renderItemParams, radius, radian) {
//       return [
//         Math.cos(radian) * radius + renderItemParams.coordSys.cx,
//         -Math.sin(radian) * radius + renderItemParams.coordSys.cy,
//       ];
//     }

//     function makePionterPoints(renderItemParams, polarEndRadian) {
//       return [
//         convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian),
//         convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian + Math.PI * 0.03),
//         convertToPolarPoint(renderItemParams, _pointerInnerRadius, polarEndRadian),
//       ];
//     }

//     function makeText(valOnRadian) {
//       return valOnRadian.toFixed(0) + unit;
//       //   return ((valOnRadian / maxValue) * 100).toFixed(0) + '%';
//       //+ '/' + maxValue + unit
//     }

//     const option = {
//       animationEasing: _animationEasingUpdate,
//       animationDuration: _animationDuration,
//       animationDurationUpdate: _animationDurationUpdate,
//       animationEasingUpdate: _animationEasingUpdate,
//       dataset: {
//         source: [[1, value]],
//       },
//       tooltip: {},
//       angleAxis: {
//         type: 'value',
//         startAngle: 0,
//         show: false,
//         min: 0,
//         max: maxValue,
//       },
//       radiusAxis: {
//         type: 'value',
//         show: false,
//       },
//       polar: {},
//       series: [
//         {
//           type: 'custom',
//           coordinateSystem: 'polar',
//           renderItem: renderItem,
//         },
//       ],
//     };

//     chart.setOption(option);

//     return () => {
//       chart.dispose();
//     };
//   }, [value, maxValue]);

//   return <div ref={chartRef} style={{ width: '100%', height: '300px' }} />;
// };

// export default CustomGaugeChart;

// import React, { useEffect, useRef } from 'react';
// import * as echarts from 'echarts';

// const CustomGaugeChart = ({ value, maxValue, unit, logoUrl }) => {
//   const chartRef = useRef(null);

//   useEffect(() => {
//     const chart = echarts.init(chartRef.current);

//     const _panelImageURL = '/asset/logo/custom-gauge-panel.png';
//     const _animationDuration = 1500;
//     const _animationDurationUpdate = 1500;
//     const _animationEasingUpdate = 'quarticInOut';
//     const _outerRadius = 120;
//     const _innerRadius = 110;
//     const _pointerInnerRadius = 20;
//     const _insidePanelRadius = 100;

//     function renderItem(params, api) {
//       const valOnRadian = api.value(1);
//       const coords = api.coord([api.value(0), valOnRadian]);
//       const polarEndRadian = coords[3];
//       const imageStyle = {
//         image: _panelImageURL,
//         x: params.coordSys.cx - _outerRadius,
//         y: params.coordSys.cy - _outerRadius,
//         width: _outerRadius * 2,
//         height: _outerRadius * 2,
//       };

//       return {
//         type: 'group',
//         children: [
//           {
//             type: 'image',
//             style: imageStyle,
//             clipPath: {
//               type: 'sector',
//               shape: {
//                 cx: params.coordSys.cx,
//                 cy: params.coordSys.cy,
//                 r: _outerRadius,
//                 r0: _innerRadius,
//                 startAngle: 0,
//                 endAngle: -polarEndRadian,
//                 transition: 'endAngle',
//                 enterFrom: { endAngle: 0 },
//               },
//             },
//           },
//           {
//             type: 'image',
//             style: imageStyle,
//             clipPath: {
//               type: 'polygon',
//               shape: {
//                 points: makePointerPoints(params, polarEndRadian),
//               },
//               extra: {
//                 polarEndRadian: polarEndRadian,
//                 transition: 'polarEndRadian',
//                 enterFrom: { polarEndRadian: 0 },
//               },
//               during: function (apiDuring) {
//                 apiDuring.setShape(
//                   'points',
//                   makePointerPoints(params, apiDuring.getExtra('polarEndRadian')),
//                 );
//               },
//             },
//           },
//           {
//             type: 'circle',
//             shape: {
//               cx: params.coordSys.cx,
//               cy: params.coordSys.cy,
//               r: _insidePanelRadius,
//             },
//             style: {
//               fill: '#fff',
//               shadowBlur: 25,
//               shadowOffsetX: 0,
//               shadowOffsetY: 0,
//               shadowColor: 'rgba(76,107,167,0.4)',
//             },
//           },
//           {
//             type: 'image',
//             style: {
//               image: logoUrl, // URL for the logo image
//               x: params.coordSys.cx - _insidePanelRadius / 2,
//               y: params.coordSys.cy - _insidePanelRadius / 2,
//               width: _insidePanelRadius,
//               height: _insidePanelRadius,
//             },
//           },
//         ],
//       };
//     }

//     function convertToPolarPoint(renderItemParams, radius, radian) {
//       return [
//         Math.cos(radian) * radius + renderItemParams.coordSys.cx,
//         -Math.sin(radian) * radius + renderItemParams.coordSys.cy,
//       ];
//     }

//     function makePointerPoints(renderItemParams, polarEndRadian) {
//       return [
//         convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian),
//         convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian + Math.PI * 0.03),
//         convertToPolarPoint(renderItemParams, _pointerInnerRadius, polarEndRadian),
//       ];
//     }

//     const option = {
//       animationEasing: _animationEasingUpdate,
//       animationDuration: _animationDuration,
//       animationDurationUpdate: _animationDurationUpdate,
//       animationEasingUpdate: _animationEasingUpdate,
//       dataset: {
//         source: [[1, value]],
//       },
//       tooltip: {},
//       angleAxis: {
//         type: 'value',
//         startAngle: 0,
//         show: false,
//         min: 0,
//         max: maxValue,
//       },
//       radiusAxis: {
//         type: 'value',
//         show: false,
//       },
//       polar: {},
//       series: [
//         {
//           type: 'custom',
//           coordinateSystem: 'polar',
//           renderItem: renderItem,
//         },
//       ],
//     };

//     chart.setOption(option);

//     return () => {
//       chart.dispose();
//     };
//   }, [value, maxValue, logoUrl]);

//   return <div ref={chartRef} style={{ width: '300px', height: '300px' }} />;
// };

// export default CustomGaugeChart;

import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';

const CustomGaugeChart = ({ value, maxValue, unit, logoUrl }) => {
  const chartRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false); // Track visibility

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger animation when the chart becomes visible
          observer.disconnect(); // Stop observing once it's visible to avoid multiple triggers
        }
      },
      { threshold: 0.1 }, // Adjust threshold as needed
    );

    if (chartRef.current) {
      observer.observe(chartRef.current);
    }

    return () => {
      if (chartRef.current) {
        observer.unobserve(chartRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return; // Only animate if visible

    const chart = echarts.init(chartRef.current);

    const _panelImageURL = '/asset/logo/custom-gauge-panel.png';
    const _animationDuration = 1000;
    const _animationDurationUpdate = 1000;
    const _animationEasingUpdate = 'quarticInOut';
    const _outerRadius = 120;
    const _innerRadius = 110;
    const _pointerInnerRadius = 20;
    const _insidePanelRadius = 100;

    function renderItem(params, api) {
      const valOnRadian = api.value(1);
      const coords = api.coord([api.value(0), valOnRadian]);
      const polarEndRadian = coords[3];
      const imageStyle = {
        image: _panelImageURL,
        x: params.coordSys.cx - _outerRadius,
        y: params.coordSys.cy - _outerRadius,
        width: _outerRadius * 2,
        height: _outerRadius * 2,
      };

      return {
        type: 'group',
        children: [
          {
            type: 'image',
            style: imageStyle,
            clipPath: {
              type: 'sector',
              shape: {
                cx: params.coordSys.cx,
                cy: params.coordSys.cy,
                r: _outerRadius,
                r0: _innerRadius,
                startAngle: 0,
                endAngle: -polarEndRadian,
                transition: 'endAngle',
                enterFrom: { endAngle: 0 },
              },
            },
          },
          {
            type: 'image',
            style: imageStyle,
            clipPath: {
              type: 'polygon',
              shape: {
                points: makePointerPoints(params, polarEndRadian),
              },
              extra: {
                polarEndRadian: polarEndRadian,
                transition: 'polarEndRadian',
                enterFrom: { polarEndRadian: 0 },
              },
              during: function (apiDuring) {
                apiDuring.setShape(
                  'points',
                  makePointerPoints(params, apiDuring.getExtra('polarEndRadian')),
                );
              },
            },
          },
          {
            type: 'circle',
            shape: {
              cx: params.coordSys.cx,
              cy: params.coordSys.cy,
              r: _insidePanelRadius,
            },
            style: {
              fill: '#fff',
              shadowBlur: 25,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowColor: 'rgba(76,107,167,0.4)',
            },
          },
          {
            type: 'image',
            style: {
              image: logoUrl, // URL for the logo image
              x: params.coordSys.cx - _insidePanelRadius / 2,
              y: params.coordSys.cy - _insidePanelRadius / 2,
              width: _insidePanelRadius,
              height: _insidePanelRadius,
            },
          },
        ],
      };
    }

    function convertToPolarPoint(renderItemParams, radius, radian) {
      return [
        Math.cos(radian) * radius + renderItemParams.coordSys.cx,
        -Math.sin(radian) * radius + renderItemParams.coordSys.cy,
      ];
    }

    function makePointerPoints(renderItemParams, polarEndRadian) {
      return [
        convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian),
        convertToPolarPoint(renderItemParams, _outerRadius, polarEndRadian + Math.PI * 0.03),
        convertToPolarPoint(renderItemParams, _pointerInnerRadius, polarEndRadian),
      ];
    }

    const option = {
      animationEasing: _animationEasingUpdate,
      animationDuration: _animationDuration,
      animationDurationUpdate: _animationDurationUpdate,
      animationEasingUpdate: _animationEasingUpdate,
      dataset: {
        source: [[1, value]],
      },
      tooltip: {},
      angleAxis: {
        type: 'value',
        startAngle: 0,
        show: false,
        min: 0,
        max: maxValue,
      },
      radiusAxis: {
        type: 'value',
        show: false,
      },
      polar: {},
      series: [
        {
          type: 'custom',
          coordinateSystem: 'polar',
          renderItem: renderItem,
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [isVisible, value, maxValue, logoUrl]);

  return <div ref={chartRef} style={{ width: '300px', height: '300px' }} />;
};

export default CustomGaugeChart;
