export const prod_config = {
  apiKey: 'AIzaSyDLHlBcQVDePIjhi_gWY-J8qs5kwGIBLJ4',
  authDomain: 'mgmd-356213.firebaseapp.com',
  projectId: 'mgmd-356213',
  storageBucket: 'mgmd-356213.appspot.com',
  messagingSenderId: '125919552297',
  appId: '1:125919552297:web:51ef243bd6dcb31c180d38',
  measurementId: 'G-FY10YH5TE4',
  firestore: {
    databaseId: 'swachtadashak',
  },
};
