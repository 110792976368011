import React from 'react';
import { Chrono } from 'react-chrono';

export default function Timeline({ items }) {
  return (
    <div style={{ height: '600px' }}>
      {items.length > 0 && (
        <Chrono
          items={items?.map((item) => ({
            title: `${item?.title}th`,
            cardTitle:
              item?.visibility == 'show' && item?.cardTitle ? (
                item?.cardTitle
              ) : (
                <span style={{ color: 'gray', pointerEvents: 'none' }}>-</span>
              ),
          }))}
          mode="VERTICAL_ALTERNATING"
          cardHeight={80}
          lineWidth={20}
          scrollable={true}
          disableToolbar={true}
          theme={{
            cardBgColor: 'white',
            cardForeColor: 'black',
          }}
        />
      )}
    </div>
  );
}
