import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getEventThemeCountById } from '../../firebase/functions';
import ThemeOneBg from '../../assets/images/backgrounds/theme_one_bg.png';
import ThemeTwoBg from '../../assets/images/backgrounds/theme_two_bg.png';
import ThemeThreeBg from '../../assets/images/backgrounds/theme_three_bg.png';
import { getEventThemeCount } from '../../api';
import TargetBg from '../../assets/images/backgrounds/targetbg.jpg';

const themeIds = [1, 2, 3];

export default function ThemeCount() {
  const [themeIdOneCount, setThemeIdOneCount] = useState(0);
  const [themeIdTwoCount, setThemeIdTwoCount] = useState(0);
  const [themeIdThreeCount, setThemeIdThreeCount] = useState(0);

  const { t } = useTranslation();

  const getEventThemeCounts = async () => {
    const responses = await getEventThemeCount();

    if (responses.status === 200) {
      const data = responses.data;

      // Iterate over the data and set the respective counts
      data.forEach((item) => {
        switch (item.event_theme_id) {
          case '1':
            setThemeIdOneCount(item.event_count);
            break;
          case '2':
            setThemeIdTwoCount(item.event_count);
            break;
          case '3':
            setThemeIdThreeCount(item.event_count);
            break;
          default:
            // Handle any unexpected theme IDs
            break;
        }
      });
    }
  };

  useEffect(() => {
    getEventThemeCounts();
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          backgroundImage: `url(${TargetBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '60vh',
          paddingTop: '20px',
          marginBottom: '30px',
        }}
      >
        <Grid item xs={12}>
          <Typography className="main-heading">{t('three_pillars')}</Typography>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 4%',
          }}
        >
          {/* Card 1 */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                minHeight: '31vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundImage: `url(${ThemeOneBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CardContent>
                <Typography variant="h1" fontWeight="bold" color="white" fontSize="2.5rem">
                  {themeIdTwoCount.toLocaleString()}
                </Typography>
                <Typography variant="h2" fontWeight="bold" color="white" fontSize="1.5rem">
                  CTU Identified & Mass cleanliness drives <br />
                  (Swachhata Lakshit Ekayi)
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Card 2 */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                minHeight: '32vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundImage: `url(${ThemeTwoBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CardContent>
                <Typography variant="h1" fontWeight="bold" mt={2} color="white" fontSize="2.5rem">
                  {themeIdOneCount.toLocaleString()}
                </Typography>
                <Typography variant="h1" fontWeight="bold" color="white" fontSize="1.5rem">
                  Swachhata Mein <br />
                  Jan Bhaagidari
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Card 3 */}
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                minHeight: '32vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundImage: `url(${ThemeThreeBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CardContent>
                <Typography variant="h1" fontWeight="bold" mt={2} fontSize="2.5rem" color="white">
                  {themeIdThreeCount.toLocaleString()}
                </Typography>
                <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                  SafaiMitra Suraksha <br />
                  Shivirs
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
