import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button } from '@mui/material';
import Navbar from '../../layouts/full-layout/header/homeHeader';
import Footer from '../../layouts/full-layout/footer/Footer';
import Banner from '../../assets/images/backgrounds/banner.png';
import MediaBanner from '../../assets/images/backgrounds/media.png';
import Leaderboard from '../../assets/images/content/leaderboard.png';
import LandingCarousel from '../../components/carousel/LandingCarousel';
import { useTranslation } from 'react-i18next';
import Map from './Map';
import Poster from '../../assets/images/backgrounds/poster.jpg';
import { useNavigate } from 'react-router';
import MediaGallery from '../../components/media-gallery';
import {
  getUserByID,
  getPermissionsByRoleID,
  getUserByUserName,
  getCurrentToken,
  getAllStates,
} from '../../firebase/functions';
// import { getEventCardDetails, getSignedUrl } from '../../api';
import EventType from '../../components/event-type';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, updateRole } from '../../redux/profile/action';
import firebase from '../../firebase';
import EventCardLanding from '../../components/eventCardLanding';
import MapComponent from '../../components/mapComponent';
import EventsBanner from '../../assets/images/backgrounds/eventsBanner.png';
import TargetUnits from '../../components/target-units';
import AnalyticalData from '../../components/analytic-section';
import TwitterWall from '../../components/twitterWall';
import {
  getCTUCount,
  getStateCount,
  getTopCtuIdentifiedArea,
  getTopCtuIdentifiedState,
  getTopGarbageCleaned,
  getSignedUrl,
  getMediaImages,
  getCtuDateRange,
  getLandingPageData,
  getReports,
} from '../../api';
import EventPlanned from '../../components/event-planned';
import ThemeCount from '../../components/themeCount';

// import Video from '../../assets/videos/flag.mp4';

const HomeAfter = () => {
  const [images, setImages] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedStateName, setSelectedStateName] = useState('');
  const [post15, setPost15] = useState(true);
  const [mapCount, setMapCount] = useState({
    total_participants: 0,
    total_media: 0,
    total_ministries: 0,
    states: [],
    ctu_identified: [],
  });
  const [topCtuIdentified, setTopCtuIdentified] = useState([]);
  const [topCtuAreawise, setTopCtuAreawise] = useState([]);
  const [topGarbageCleaned, setTopGarbageCleaned] = useState([]);
  const [ctuDatewise, setCtuDatewise] = useState([]);
  const [landingPageData, setLandingPageData] = useState([]);
  const [ctuIdentified, setCtuIdentified] = useState();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [leaderboardData, setLeaderBoardData] = useState([]);
  const [reportData, setReportData] = useState([]);

  const { logged_in } = useSelector((state) => state.ProfileReducer);

  const getStates = async () => {
    const statesData = await getAllStates();

    setStates(
      statesData
        ?.map((o) => {
          return { label: o.state_name, value: o.state_code };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
  };

  useEffect(() => {
    getStates();
  }, []);

  const analyticalData = [
    {
      title: 'Total Media',
      value: 100,
      img: './asset/tempAsset/TransformativeEvent.png',
    },
    {
      title: 'Total Participants',
      value: 100,
      img: './asset/tempAsset/TransformativeEvent1.png',
    },
    {
      title: 'Total Ministries',
      value: 100,
      img: './asset/tempAsset/TransformativeEvent2.png',
    },
  ];

  const getImages = async () => {
    try {
      const imagesData = await getMediaImages(1, 10);
      const imageData = imagesData?.data?.data;

      if (imageData) {
        setImages((prevImages) => [...prevImages, ...imageData]);
      }
      // setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const getUserDetails = async (e) => {
    const userRecord = await getUserByID(e);
    const data = await getPermissionsByRoleID(userRecord?.role_id);
    const userDetails = await getUserByUserName(userRecord?.user_name);
    const token = await getCurrentToken();
    dispatch(updateRole({ info: data }));
    dispatch(
      updateProfile({
        info: {
          user_record: userRecord,
          user_details: userDetails,
        },
        user_role: userRecord?.user_role,
        logged_in: true,
        token: token,
      }),
    );
    localStorage.setItem('token', token);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await getEventCardDetails();
  //     setEventData(data); // save the data in the state
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    if (!logged_in) {
      firebase?.auth().onAuthStateChanged(async (res) => {
        if (res) {
          await getUserDetails(res?.uid);
        }
      });
    }
  }, []);

  useEffect(() => {
    setEventData(analyticalData);
    getImages();

    // Fetch landing page data
    getLandingPageData().then((res) => {
      setLandingPageData(res?.data);
      setMapCount((prevMapCount) => ({
        ...prevMapCount,
        states: res?.data?.state_code_event_data,
        ctu_identified: res?.data?.state_code_ctu_identification_data,
      }));
      setTopCtuAreawise(res?.data?.area_type_ctu_identification_data);
      setCtuDatewise(res?.data?.data_datewise);
      setTopCtuIdentified(res?.data?.top_leaderboard_ctu);
      setLeaderBoardData(res?.data?.state_code_ctu_identification_data);
      if (res?.data?.data_datewise?.length) {
        setCtuIdentified(res?.data?.data_datewise[res?.data?.data_datewise?.length - 1]?.count);
      } else {
        // Handle the case when date_range is undefined, null, or empty
        setCtuIdentified(0); // Or any other fallback value you prefer
      }
      // console.log('landing page data', res?.data);
    });

    getReports().then((res) => {
      setReportData(res?.data);
    });
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Navbar />
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          overflowX: 'hidden',
          position: 'relative',
          marginTop: '70px',
        }}
      >
        <LandingCarousel />

        <Box
          sx={{
            width: '80%',
            margin: 'auto',
            textAlign: 'center',
            mb: 3,
            zIndex: 2,
            marginTop: '3%',
          }}
        >
          <Typography className="main-heading" variant="h1">
            {t('main-heading')}
          </Typography>
          {/* <Typography variant="h3" className="sub-heading" mt={1}>
            {t('sub-heading')}
          </Typography> */}
          <Typography variant="h1" className="sub-para" mt={2}>
            {t('sub-para')}
          </Typography>

          <Button
            color="secondary"
            variant="contained"
            size="large"
            type="submit"
            sx={{ mt: '10px', pt: '10px', pb: '10px' }}
            onClick={() => navigate('/events-map')}
          >
            Participate in Public Events
          </Button>
        </Box>

        {/* <EventCardLanding eventData={eventData} /> */}

        <MapComponent
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          setSelectedStateName={setSelectedStateName}
          selectedStateName={selectedStateName}
          states={states}
          mapCount={mapCount}
          post15={post15}
          topCtuIdentified={topCtuIdentified}
          leaderboardData={leaderboardData}
          topGarbageCleaned={topGarbageCleaned}
          ctuIdentified={ctuIdentified}
        />

        <ThemeCount />

        <TargetUnits post15={post15} topCtuAreawise={topCtuAreawise} ctuDatewise={ctuDatewise} />

        <EventPlanned reportData={reportData} />

        {post15 && <AnalyticalData eventData={eventData} />}

        {/* <EventType /> */}

        {/* {post15 && images.length > 0 && <MediaGallery images={images} />} */}

        <TwitterWall />
        <Box
          sx={{
            mx: 5,
            px: 5,
            mt: 4,
            mb: 3,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <div className="flag_video">
            <iframe
              width="500"
              min-height="400"
              height={window.innerWidth > 767 ? '400' : '200'}
              className="img-fluid "
              src="https://www.youtube.com/embed/GmAxkyroG6U?si=sRbE2q05ii3FNg-g"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ borderRadius: '10px' }}
            />
          </div>
        </Box>
      </Box>
      <Footer imgUrl="./asset/footer/homeFooter.png" />
    </div>
  );
};

export default HomeAfter;
