import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import FlagBg from '../../assets/images/backgrounds/flag.gif';
import FlagBg from '../../assets/images/backgrounds/poster2.png';
import PhoneFlagBg from '../../assets/images/backgrounds/phone_poster.png';
import Leaderboard from '../../assets/images/content/leaderboard.png';
import Leaderboard1 from '../../assets/images/content/leaderboard1.png';
import GoldIcon from '../../assets/images/logos/gold.png';
import SilverIcon from '../../assets/images/logos/silver.png';
import BronzeIcon from '../../assets/images/logos/bronze.png';
import Map from '../../views/home/Map';
import { useLocation, useParams } from 'react-router';
import { Typography } from '@mui/material';
import { Card } from 'react-bootstrap';

export default function MapComponent({
  selectedState,
  setSelectedState,
  states,
  setSelectedStateName,
  selectedStateName,
  mapCount,
  post15,
  topCtuIdentified,
  topGarbageCleaned,
  leaderboardData,
  ctuIdentified,
  totalCtuJoinedAdopted
}) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const screenWidth = window.innerWidth;
  // console.log('topCtuIdentified', topCtuIdentified);
  return (
    <div className="container-fluid flag-cont py-5">
      <img
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          // filter: 'blur(2px)',
          // zIndex: -1,
        }}
        src={screenWidth > 767 ? FlagBg : PhoneFlagBg}
        alt="flag"
      />

      <div
        className="container-fluid"
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          zIndex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          // display: 'flex',
        }}
      >
        <div
          className="row d-flex align-items-center justify-content-center"
          style={{ paddngTop: '5%' }}
        >
          <div className="col-md-6">
            <Map
              states={states}
              selectedState={selectedState}
              selectedStateName={selectedStateName}
              setSelectedState={setSelectedState}
              setSelectedStateName={setSelectedStateName}
              mapCount={mapCount}
              post15={post15}
            />
          </div>
          <div className="col-md-6">
            <div className="container">
              {!post15 && (
                <div className="box">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-6 text-center">
                      <h3 className="flag-card-head">{t('total_ctu_identified')}</h3>
                      <h1 className="flag-card-num">{ctuIdentified}</h1>
                    </div>
                    {/* <div className="col-6 text-center">
                      <h3 className="flag-card-head">{t('total_ctu_adopt_joined')}</h3>
                      <h1 className="flag-card-num">{totalCtuJoinedAdopted}</h1>
                    </div> */}
                    {/* <div className="col-6 text-center">
                      <h3 className="flag-card-head">{t('total_media')}</h3>
                      <h1 className="flag-card-num">6,54,356</h1>
                    </div> */}
                  </div>
                </div>
              )}
              {/* {post15 ? (
                <div className="row  d-flex align-items-center justify-content-center mt-5">
                  <div className="col-12">
                    <Typography className="main-heading text-center">{t('ctu_cleaned')}</Typography>
                  </div>
                  {topGarbageCleaned[0]?.state_name && (
                    <div className="col-12 my-3 position-relative">
                      <img
                        src={GoldIcon}
                        alt="leaderboard"
                        className="img-fluid position-absolute start-30 top-50"
                        style={{ transform: 'translate(7%, -40%)', zIndex: 1 }}
                      />
                      <Card className="leaderboard_card">
                        <Card.Body>
                          <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-2"></div>
                            <div className="col-6 text-center">
                              <Typography className="flag-card-head">
                                {topGarbageCleaned[0]?.state_name}
                              </Typography>
                            </div>
                            <div className="col-4 text-center">
                              <Typography className="flag-card-head">
                                {topGarbageCleaned[0]?.total_garbage_collected}
                              </Typography>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                  {topGarbageCleaned[1]?.state_name && (
                    <div className="col-12 my-3 position-relative">
                      <img
                        src={SilverIcon}
                        alt="leaderboard"
                        className="img-fluid position-absolute start-30 top-50"
                        style={{ transform: 'translate(7%, -40%)', zIndex: 1 }}
                      />
                      <Card className="leaderboard_card">
                        <Card.Body>
                          <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-2"></div>
                            <div className="col-6 text-center">
                              <Typography className="flag-card-head">
                                {topGarbageCleaned[1]?.state_name}
                              </Typography>
                            </div>
                            <div className="col-4 text-center">
                              <Typography className="flag-card-head">
                                {' '}
                                {topGarbageCleaned[1]?.total_garbage_collected}
                              </Typography>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                  {topGarbageCleaned[2]?.state_name && (
                    <div className="col-12 my-3 position-relative">
                      <img
                        src={BronzeIcon}
                        alt="leaderboard"
                        className="img-fluid position-absolute start-30 top-50"
                        style={{ transform: 'translate(14%, -40%)', zIndex: 1 }}
                      />
                      <Card className="leaderboard_card">
                        <Card.Body>
                          <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-2"></div>
                            <div className="col-6 text-center">
                              <Typography className="flag-card-head">
                                {topGarbageCleaned[2]?.state_name}
                              </Typography>
                            </div>
                            <div className="col-4 text-center">
                              <Typography className="flag-card-head">
                                {' '}
                                {topGarbageCleaned[2]?.total_garbage_collected}
                              </Typography>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                  {topGarbageCleaned[3]?.state_name && (
                    <div className="col-12 my-3">
                      <Card className="leaderboard_card">
                        <Card.Body>
                          <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-2 justify-content-center">
                              <Typography className="leaderboard_card_head">4</Typography>
                            </div>
                            <div className="col-6 text-center">
                              <Typography className="flag-card-head">
                                {topGarbageCleaned[3]?.state_name}
                              </Typography>
                            </div>
                            <div className="col-4 text-center">
                              <Typography className="flag-card-head">
                                {' '}
                                {topGarbageCleaned[3]?.total_garbage_collected}
                              </Typography>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                  {topGarbageCleaned[4]?.state_name && (
                    <div className="col-12 my-3">
                      <Card className="leaderboard_card">
                        <Card.Body>
                          <div className="row d-flex align-items-center justify-content-center">
                            <div className="col-2">
                              <Typography className="leaderboard_card_head">5</Typography>
                            </div>
                            <div className="col-6 text-center">
                              <Typography className="flag-card-head">
                                {topGarbageCleaned[4]?.state_name}
                              </Typography>
                            </div>
                            <div className="col- text-center">
                              <Typography className="flag-card-head">
                                {topGarbageCleaned[4]?.total_garbage_collected}
                              </Typography>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>
              ) : ( */}
              <div className="row  d-flex align-items-center justify-content-center mt-5">
                <div className="col-12">
                  <Typography className="main-heading text-center">
                    {t('statewise_ctu_identified')}
                  </Typography>
                </div>
                {leaderboardData?.length > 0 && leaderboardData[0]?.state_name && (
                  <div className="col-12 my-3 position-relative">
                    <img
                      src={GoldIcon}
                      alt="leaderboard"
                      className="img-fluid position-absolute start-30 top-50"
                      style={{ transform: 'translate(7%, -40%)', zIndex: 1 }}
                    />
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2"></div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[0]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[0]?.event_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                {leaderboardData?.length > 1 && leaderboardData[1]?.state_name && (
                  <div className="col-12 my-3 position-relative">
                    <img
                      src={SilverIcon}
                      alt="leaderboard"
                      className="img-fluid position-absolute start-30 top-50"
                      style={{ transform: 'translate(7%, -40%)', zIndex: 1 }}
                    />
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2"></div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[1]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[1]?.event_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                {leaderboardData?.length > 2 && leaderboardData[2]?.state_name && (
                  <div className="col-12 my-3 position-relative">
                    <img
                      src={BronzeIcon}
                      alt="leaderboard"
                      className="img-fluid position-absolute start-30 top-50"
                      style={{ transform: 'translate(14%, -40%)', zIndex: 1 }}
                    />
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2"></div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[2]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[2]?.event_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                {leaderboardData?.length > 3 && leaderboardData[3]?.state_name && (
                  <div className="col-12 my-3">
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2 justify-content-center">
                            <Typography className="leaderboard_card_head">4</Typography>
                          </div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[3]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {' '}
                              {leaderboardData[3]?.event_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}
                {leaderboardData?.length > 4 && leaderboardData[4]?.state_name && (
                  <div className="col-12 my-3">
                    <Card className="leaderboard_card">
                      <Card.Body>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className="col-2 justify-content-center">
                            <Typography className="leaderboard_card_head">5</Typography>
                          </div>
                          <div className="col-6 text-center">
                            <Typography className="flag-card-head">
                              {leaderboardData[4]?.state_name}
                            </Typography>
                          </div>
                          <div className="col-4 text-center">
                            <Typography className="flag-card-head">
                              {' '}
                              {leaderboardData[4]?.event_count}
                            </Typography>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                )}
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
