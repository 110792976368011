export const ROLES = 'role_master';
export const PERMISSIONS = 'permission';
export const USERS = 'users';
export const CITY_REPORTS = 'city_reports';
export const USERS_DETAILS = 'user_details';
export const MINISTRIES = 'ministry_master';
export const EVENT_THEME_MASTER = 'event_theme_master';
export const EVENT_HEADING_MASTER = 'event_heading_master';
export const EVENT_NAME_MASTER = 'event_name_master';
export const COMPONENT = 'components';
export const DISTRICT_MASTER = 'state_district_master';
export const EVENTS = 'events';
export const CITY_REPORTS_ACTIVITY = 'city_reports_activity';
export const EVENT_MEDIA = 'event_media';
export const STATE_MASTER = 'state_master';
export const ULB_MASTER = 'ulb_master';
export const BLOCK_MASTER = 'district_block_master';
export const MINISTRY_DEPARTMENT_MASTER = 'ministry_department_master';
export const MINISTRY_UNIT_MASTER = 'ministry_unit_master';
export const BLOCKS_GP_MASTER = 'gp_master';
export const GP_VILLAGE_MASTER = 'village_master';
export const SBM = 'sbm';
export const TWEETS = 'tweets';
export const CITIZEN_TESTIMONIAL = 'citizen_testimonials';
export const LOCATION_TYPES = [
  { label: 'Urban', value: 'URBAN' },
  { label: 'Rural', value: 'RURAL' },
];
