import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button } from '@mui/material';
import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import TargetBg from '../../assets/images/backgrounds/targetbg.jpg';
import TargetBg1 from '../../assets/images/backgrounds/targetbg1.png';
import TargetBg2 from '../../assets/images/backgrounds/targetbg2.png';
import waterIcon from '../../assets/images/logos/water.png';
import binIcon from '../../assets/images/logos/bin.png';
import landIcon from '../../assets/images/logos/land.png';
import binAfter from '../../assets/images/logos/bin_after.png';
import landAfter from '../../assets/images/logos/land_after.png';
import airport from '../../assets/images/logos/airport.png';
import camera from '../../assets/images/logos/camera.png';
import education from '../../assets/images/logos/education.png';
import gaushala from '../../assets/images/logos/gaushala.png';
import medic from '../../assets/images/logos/medic.png';
import house from '../../assets/images/logos/house.png';
import market from '../../assets/images/logos/market.png';
import pray from '../../assets/images/logos/pray.png';
import road from '../../assets/images/logos/road-map.png';
import river from '../../assets/images/logos/river.png';
import slum from '../../assets/images/logos/slum.png';
import trainStation from '../../assets/images/logos/train-station.png';
import zoo from '../../assets/images/logos/zoo.png';
import work from '../../assets/images/logos/work.png';

import targetBar from '../../assets/images/logos/target_bar.png';
import CustomGaugeChart from '../gauge-chart';
import ProgressBarComponent from '../progress-bar';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Chrono } from 'react-chrono';
import Timeline from '../timeline';
import { getCtuDateRange } from '../../api';

export default function TargetUnits({ post15, topCtuAreawise, ctuDatewise }) {
  const { t } = useTranslation();
  const { location } = useLocation();
  const screenWidth = window.innerWidth;
  const [items, setItems] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const logoMapping = [
    { icon: airport, area: 'Airports and surrounding areas' },
    { icon: camera, area: 'Tourist locations' },
    { icon: education, area: 'Areas surrounding schools and colleges' },
    { icon: gaushala, area: 'Gaushalas' },
    { icon: house, area: 'Residential areas' },
    { icon: market, area: 'Market spaces' },
    { icon: medic, area: 'Health Institutions and Adjacent Areas' },
    { icon: pray, area: 'Places of worship' },
    { icon: road, area: 'Roadsides (NH, SH, Local)' },
    { icon: river, area: 'Waterbodies' },
    { icon: slum, area: 'Slums' },
    { icon: trainStation, area: 'Railway tracks and stations' },
    { icon: work, area: 'Areas surrounding public & private offices' },
    { icon: zoo, area: 'Zoos and wildlife areas' },
    { icon: trainStation, area: 'Bus stands and toll plaza' },
  ];

  const getIconByAreaType = (areaType) => {
    const match = logoMapping.find((logo) => logo.area === areaType);
    return match ? match.icon : landIcon; // Default to landIcon if no match is found
  };

  useEffect(() => {
    if (!ctuDatewise || ctuDatewise.length === 0) return;

    // Step 1: Map all dates from ctuDatewise
    const transformedItems = ctuDatewise.map((item) => {
      return {
        title: item.date, // Use the date as the title for each step
        date_num: item.date.split('-').pop(), // Extract the day part of the date
        cardTitle: item.count !== undefined ? item.count : null, // Show the count for each date
        visibility: item.visibility,
      };
    });

    // Step 2: Find the last index where visibility is 'show'
    const lastVisibleIndex = ctuDatewise
      .map((item, index) => (item.visibility === 'show' ? index : -1))
      .filter((index) => index !== -1) // Remove non-visible dates
      .pop(); // Get the last visible date index

    // Step 3: Calculate progress percentage based on the last 'show' date
    // The progress should be calculated based on the number of steps, not the entire bar length.
    const progressPercentage =
      lastVisibleIndex !== undefined ? (lastVisibleIndex / (ctuDatewise.length - 1)) * 100 : 0;

    // Step 4: Update state with all items and calculated progress percentage
    setItems(transformedItems);
    setProgressPercentage(progressPercentage);
  }, [ctuDatewise]);

  // useEffect(() => {
  //   if (!ctuDatewise || ctuDatewise.length === 0) return;

  //   // Step 1: Map all dates from ctuDatewise to display all dates in the progress bar
  //   const transformedItems = ctuDatewise.map((item) => {
  //     return {
  //       title: item.date, // Use the date as the title for each step
  //       date_num: item.date.split('-').pop(), // Extract the day part of the date
  //       cardTitle: item.count !== undefined ? item.count : null, // Show the count for each date
  //     };
  //   });

  //   // Step 2: Find the last date where visibility is 'show'
  //   const lastVisibleIndex = ctuDatewise
  //     .map((item, index) => (item.visibility === 'show' ? index : -1))
  //     .filter((index) => index !== -1) // Remove non-visible dates
  //     .pop(); // Get the last visible date index

  //   // If no dates are visible, the progress should be 0
  //   const progressPercentage =
  //     lastVisibleIndex !== undefined ? ((lastVisibleIndex + 1) / ctuDatewise.length) * 100 : 0;

  //   // Step 3: Update state with all dates for items and progress percentage for visible ones
  //   setItems(transformedItems);
  //   setProgressPercentage(progressPercentage);
  // }, [ctuDatewise]);

  // useEffect(() => {
  //   if (!ctuDatewise || ctuDatewise.length === 0) return;

  //   // Step 1: Map all dates from ctuDatewise to display all dates in the progress bar
  //   const transformedItems = ctuDatewise.map((item) => {
  //     return {
  //       title: item.date, // Use the date as the title for each step
  //       cardTitle: item.count !== undefined ? item.count : null, // Show the count for each date
  //     };
  //   });

  //   // Step 2: Calculate progress based on the dates where visibility is 'show'
  //   const visibleDates = ctuDatewise.filter((item) => item.visibility === 'show');
  //   const totalVisibleDates = visibleDates.length;

  //   // Progress percentage calculation
  //   const completedVisibleDates = visibleDates.filter((item) => item.date_count > 0).length;
  //   const progressPercentage = (completedVisibleDates / totalVisibleDates) * 100;

  //   // Step 3: Update state with all dates for items and progress percentage for visible ones
  //   setItems(transformedItems);
  //   setProgressPercentage(progressPercentage);
  // }, [ctuDatewise]);

  // useEffect(() => {
  //   if (!ctuDatewise || ctuDatewise.length === 0) return;

  //   // List of required dates to track progress for
  //   const requiredDates = ['7', '9', '11', '13', '15'];

  //   // Create a map from API data, mapping the 'end' date of each date_range to its count
  //   const apiMap = ctuDatewise.reduce((acc, item) => {
  //     const endDate = item.date_range.split(' to ')[1]; // Extract the 'to' part of '5 to 7'
  //     acc[endDate] = item.count; // Associate the count with the end date
  //     return acc;
  //   }, {});

  //   // Map the requiredDates to an array of items, marking those that exist in apiMap
  //   const transformedItems = requiredDates.map((date) => {
  //     return {
  //       title: date,
  //       cardTitle: apiMap[date] !== undefined ? apiMap[date] : null, // If present, show the count, else null
  //     };
  //   });

  //   // Find how many date ranges have been completed (i.e., exist in apiMap)
  //   // We skip the first date '7' and start tracking progress only from '9'
  //   let completedRanges = 0;
  //   for (let i = 1; i < requiredDates.length; i++) {
  //     if (apiMap[requiredDates[i]] !== undefined) {
  //       completedRanges++;
  //     }
  //   }

  //   // Calculate the progress percentage based on completed ranges after '7'
  //   const percentage = (completedRanges / (requiredDates.length - 1)) * 100; // Exclude the first '7'

  //   // Update the component's state
  //   setItems(transformedItems);
  //   setProgressPercentage(percentage);
  // }, [ctuDatewise]);

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          backgroundImage: `url(${TargetBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '90vh',
          paddingBottom: '50px',
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              width: '80%',
              textAlign: 'center',
              zIndex: 2,
              margin: '40px auto 40px',
            }}
          >
            <Typography className="main-heading">{t('cleaniness_target_units')}</Typography>
            <Grid item xs={12}>
              <Typography variant="h1" className="sub-para" mt={2}>
                A Cleanliness Target Unit (CTU) refers to severely neglected, high-risk areas like
                garbage points or dumpsites that pose significant environmental and health risks,
                often overlooked during routine cleanliness drives in various regions.
              </Typography>
            </Grid>
          </Box>
          <Grid item xs={12} sx={{ color: '#2F42D1' }}>
            <Typography variant="p" className="bar_uppertext">
              {t('ctu_identified')}
            </Typography>
          </Grid>

          <Grid container direction="row" alignItems="center" justifyContent="center">
            {items.length > 0 && (
              <Grid item xs={11} sm={9} sx={{ marginBottom: '30px' }}>
                {screenWidth > 800 ? (
                  <ProgressBarComponent items={items} progressPercentage={progressPercentage} />
                ) : (
                  <Timeline items={items} />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Before 15th Sept 2024  */}
        {!post15 && (
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {topCtuAreawise.map((item, index) => (
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid
                      item
                      xs={4}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                      }}
                    >
                      <img
                        className="img-fluid clean_logo"
                        src={getIconByAreaType(item?.area_type)}
                        alt="target"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                      }}
                    >
                      <Box
                        sx={{
                          textAlign: 'start',
                          ml: 2,
                        }}
                      >
                        <Typography variant="h3" className="target_units" sx={{ color: '#3C7A16' }}>
                          {item?.event_count} {t('ctu_identified')}
                        </Typography>
                        <Typography variant="p" className="target_units_sub">
                          {item?.area_type}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}
        {/* After 15th Sept 2024 */}
        {post15 && (
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CustomGaugeChart value={300} maxValue={500} unit="t" logoUrl={binAfter} />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'start',
                        ml: 2,
                      }}
                    >
                      <Typography
                        variant="h3"
                        className="target_units"
                        sx={{
                          color: '#3C7A16',
                        }}
                      >
                        26726 Sq.m
                      </Typography>
                      <Typography variant="p" className="target_units_sub">
                        {t('garbage_cleaned')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CustomGaugeChart value={620} maxValue={800} unit="t" logoUrl={landAfter} />{' '}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: 'start',
                        ml: 2,
                      }}
                    >
                      <Typography
                        variant="h3"
                        className="target_units"
                        sx={{
                          color: '#843F0E',
                        }}
                      >
                        26726 Sq.m
                      </Typography>
                      <Typography variant="p" className="target_units_sub">
                        {t('area_cleaned')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
