import React, { useEffect, useState } from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

const ProgressBarComponent = ({ items, progressPercentage }) => {
  return (
    <div style={{ width: '80%', margin: 'auto', marginTop: '50px', position: 'relative' }}>
      <ProgressBar
        filledBackground="#2938a9"
        unfilledBackground="#b8c0ff"
        percent={progressPercentage} // Progress bar filled up to the last present date
        height={30}
      >
        {items.map((item, index) => (
          <Step key={index}>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${accomplished ? 'accomplished' : ''}`}
                style={{
                  cursor: item.cardTitle !== null ? 'pointer' : 'not-allowed', // Only make clickable if accomplished
                  fontSize: '12px',
                  //   marginTop: '-15px',
                  textAlign: 'center',
                  position: 'relative',
                  color: item.cardTitle !== null ? '#000' : '#ccc', // Grey out if not accomplished
                }}
              >
                {item?.date_num}
                <sup>th</sup>

                {/* Show cardTitle only if it exists */}
                {item.cardTitle !== null && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '40px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      //   backgroundColor: '#fff',
                      //   padding: '5px',
                      //   borderRadius: '4px',
                      //   boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
                      //   marginTop: '10px',
                    }}
                  >
                    {item?.visibility == 'show' && (
                      <h5 style={{ fontWeight: 'bold' }}>{item.cardTitle}</h5>
                    )}
                  </div>
                )}
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
};

export default ProgressBarComponent;
