import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// import { Modal } from 'react-bootstrap';
// import {
//   FacebookShareButton,
//   LinkedinShareButton,
//   TwitterShareButton,
//   FacebookIcon,
//   TwitterIcon,
//   LinkedinIcon,
//   WhatsappShareButton,
//   WhatsappIcon,
// } from 'react-share';
// Import certificate images for different languages
import CertificateFlagEn from '../../assets/images/certificate/Certificate.png';

function Certificate({ username, handleCloseCertificateModal }) {
  const { t, i18n } = useTranslation();
  const [imageUri, setUri] = useState(null);
  const [share, setShare] = useState(false);
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    // Setup the Canvas
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.src = CertificateFlagEn;
    // console.log("username", username);
    if (username?.length != 0) {
      img.onload = function () {
        // Set Canvas resolution as per image
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.crossOrigin = 'Anonymous';

        // Draw Image & Add Name
        ctx.font = 'bold 85pt Arial, cursive';
        const userName = username;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#bd8e33';
        ctx.textAlign = 'center';
        ctx.fillText(userName, canvas.width / 2, canvas.height / 1.75);

        // Show the Image in the UI
        img.uri = canvas.toDataURL('image/jpg');
        setUri(img.uri);
      };
    }
  }, [username, i18n.language]);

  return (
    <>
      <div className="modalBackground-certificate">
        <div className="modal-container-certificate">
          <div className="popup-header-certificate">
            <canvas id="canvas" className="certificate-canvas"></canvas>
          </div>
          <div>
            <div className="row">
              <button
                className="col text-center orange_btn cursor-pointer"
                onClick={() => {
                  const link = document.createElement('a');
                  link.download = `${username}.jpg`;
                  link.href = imageUri;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                {t('download')}
              </button>
              <button
                className="col text-center orange_btn cursor-pointer"
                onClick={() => {
                  handleCloseCertificateModal();
                }}
              >
                {t('close')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        id="shareModal"
        className="modal fade"
        show={share}
        onHide={() => setShare(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('share_with_community')}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            textAlign: 'center',
          }}
        >
          <div className="div-icon">
            <FacebookShareButton
              url={`https://harghartiranga.com/certificate/${id}`}
              quote="Let's come forward and contribute our part in hoisting our Indian flag and putting a selfie with it."
              hashtag="harghartiranga"
              style={{ margin: '0 5px' }}
            >
              <FacebookIcon size="2.5rem" round />
            </FacebookShareButton>
            <LinkedinShareButton
              url={`https://harghartiranga.com/certificate/${id}`}
              title="Let's come forward and contribute our part in hoisting our Indian flag and putting a selfie with it."
              summary="harghartiranga"
              source="harghartirana.in"
              style={{ margin: '0 5px' }}
            >
              <LinkedinIcon size="2.5rem" round />
            </LinkedinShareButton>
            <TwitterShareButton
              title="Let's come forward and contribute our part in hoisting our Indian flag and putting a selfie with it."
              url={`https://harghartiranga.com/certificate/${id}`}
              hashtags={['harghartiranga']}
              style={{ margin: '0 5px' }}
            >
              <TwitterIcon size="2.5rem" round />
            </TwitterShareButton>
            <WhatsappShareButton
              title="Let's come forward and contribute our part in hoisting our Indian flag and putting a selfie with it."
              url={`https://harghartiranga.com/certificate/${id}`}
              hashtags={['harghartiranga']}
              style={{ margin: '0 5px' }}
            >
              <WhatsappIcon size="2.5rem" round />
            </WhatsappShareButton>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button type="button" className="btn btn-secondary" onClick={() => setShare(false)}>
            {t('close')}
          </button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default Certificate;
