import React, { useEffect, useState, useRef } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Container, Row, Col, Spinner, Carousel, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import { fetchTweetData } from '../../firebase/functions';
import Slider from 'react-slick';
// import thumbnail from '../../assets/images/content/thumbnail.png';
import { Typography } from '@mui/material';
import { getTweetIDs } from '../../firebase/functions';

function TwitterWall() {
  const screenSize = window.innerWidth;
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // const statictweetData = ['1834097904845234564', '1833734380814033284', '1834089105501630940'];
  const [tweetstatic, setTweetstatic] = useState([]);
  const [tweets, setTweets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [display, setDisplay] = useState(true);
  const [width, setWidth] = useState(600);
  const sliderRef = useRef(null);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    const getData = async () => {
      const res = await getTweetIDs();
      if (res.length > 0) {
        setTweetstatic(res.map((o) => o.tweet_id));
      }
    };
    getData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: screenSize > 900 ? 4 : 1,
    slidesToScroll: screenSize > 900 ? 2 : 1,
  };

  //   const getTweetData = async () => {
  //     const data = await fetchTweetData();
  //     setTweets(data);
  //     setLoading(false);
  //   };

  // useEffect(() => {
  //   setTweetstatic(statictweetData);
  //   // getTweetData();
  // }, []);

  return (
    <div>
      <div
        className="hashtag-div"
        style={{
          paddingTop: '5%',
        }}
      >
        <Typography variant="h1" sx={{ color: '#3939A0', fontWeight: '700', textAlign: 'center' }}>
          {t('social_media_feed')}
        </Typography>
        <Container style={{ justifyContent: 'center', display: 'flex', marginTop: '2%' }}>
          <Row>
            <Col xs={12} md={6} lg={2} className="target_units_sub text-center">
              #SHS2024
            </Col>
            <Col xs={12} md={6} lg={4} className="target_units_sub text-center">
              #SwachhataHiSeva2024
            </Col>
            <Col xs={12} md={6} lg={3} className="target_units_sub text-center">
              #SwabhavSwachhata
            </Col>
            <Col xs={12} md={6} lg={3} className="target_units_sub text-center">
              #SanskaarSwachhata
            </Col>
          </Row>
        </Container>
      </div>

      <Container
        // className="tweet-cont my-5"
        fluid
        style={{
          marginBottom: '5%',
          display: screenSize > 768 && 'flex',
          justifyContent: 'center',
          backgroundColor: 'white',
          padding: '20px',
        }}
      >
        <Row
          className="tweet-row"
          style={{
            display: screenSize > 768 ? 'contents' : 'block',
            // width: '100vw',
          }}
        >
          {tweetstatic.map((item) => (
            <Col
              xl={4}
              lg={4}
              md={4}
              sm={12}
              key={item}
              className="hashtagImg_sm"
              // style={{
              //   display: 'flex',
              //   textAlign: 'center',
              //   justifyContent: 'center',
              // }}
            >
              <TwitterTweetEmbed
                className="text-center justify-content-center d-flex"
                tweetId={item}
                placeholder={
                  <>
                    <Spinner animation="border" role="status">
                      <span className="sr-only"></span>
                    </Spinner>
                  </>
                }
                options={{
                  cards: 'hidden',
                  hideCard: true,
                  hideThread: false,
                  maxWidth: 500,
                  width: 400,
                }}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default TwitterWall;
