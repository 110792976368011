import React, { useEffect } from 'react';
import PageContainer from '../../components/container/PageContainer';
import { Card, CardContent } from '@mui/material';

function CTUMapContainer() {
  function loadEventScripts() {
    // Load your custom script for events here
    var customScript = document.createElement('script');
    customScript.src = 'mapctu.js';
    customScript.async = true;
    customScript.defer = true;
    document.body.appendChild(customScript);

    // Load Google Maps API script with the callback
    var googleMapsScript = document.createElement('script');
    googleMapsScript.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCUuT4VmoNqq_8FockZkmFU9upMdONeRUI&callback=initMap';
    googleMapsScript.async = true;
    googleMapsScript.defer = true;
    document.body.appendChild(googleMapsScript);
  }
  useEffect(() => {
    loadEventScripts();
  }, []);

  return (
    <div style={{ marginTop: '5rem' }}>
      <PageContainer title="Starter Page" description="this is Starter Page">
        <div style={{textAlign:"center",margin:"1rem",position:"relative",top:"10px"}}>
      <h3>Adopt CTU</h3>
      </div>
        <Card sx={{ width: '90%', margin: 'auto', marginTop: '10px', marginBottom: '10px' }}>
          <CardContent>
            <div
              id="mapCTU"
              style={{
                marginTop: '0.1rem',
                width: '100%',
                height: '80vh',
                border: '1px solid #d3d3d3',
              }}
            ></div>
          </CardContent>
        </Card>
        <h3>Note : Map updated every 1 hour</h3>
      </PageContainer>
    </div>
  );
}

export default CTUMapContainer;
