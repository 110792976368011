import { UPDATE_PROFILE, UPDATE_ROLE_DETAILS } from '../constants';

export const updateProfile = (value) => ({
  type: UPDATE_PROFILE,
  value,
});

export const updateRole = (value) => ({
  type: UPDATE_ROLE_DETAILS,
  value,
});
