import React from 'react';
import { Box, Grid, Typography, Card, CardContent, Button } from '@mui/material';
import EventsBanner1 from '../../assets/images/backgrounds/event1.jpg';
import EventsBanner2 from '../../assets/images/backgrounds/event2.jpg';
import EventsBanner3 from '../../assets/images/backgrounds/event3.jpg';
import EventsBanner4 from '../../assets/images/backgrounds/event4.jpg';
import EventsBanner5 from '../../assets/images/backgrounds/event5.jpg';
import EventsBanner6 from '../../assets/images/backgrounds/event6.jpg';
import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router';

const Item = styled(Paper)(({ bgImg }) => ({
  backgroundImage: `url(${bgImg})`,
  textAlign: 'center',
  height: '100%',
  width: '100%',
  borderRadius: '10px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

export default function EventType() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: '80%',
        margin: 'auto',
        textAlign: 'center',
        mt: 5,
        mb: 3,
      }}
    >
      <Typography variant="h1" sx={{ color: '#3939A0', fontWeight: '700', paddingY: '2%' }}>
        Explore Events
      </Typography>
      <Grid container spacing={2}>
        <Grid
          item
          xs={8}
          sx={{
            height: '400px',
          }}
          onClick={() => {
            navigate(`/event-type/TymKA00Y5BmZiXnuGbLJ`);
          }}
        >
          <Item bgImg={EventsBanner1}></Item>
        </Grid>
        <Grid item xs={4}>
          <Grid
            item
            xs={12}
            sx={{
              height: '190px',
              width: '100%',
              paddingBottom: '5px',
            }}
            onClick={() => {
              navigate(`/event-type/oalzYFuCNC8aCPR0sKvk`);
            }}
          >
            <Item bgImg={EventsBanner2}></Item>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: '190px',
              width: '100%',
              paddingTop: '5px',
            }}
            onClick={() => {
              navigate(`/event-type/SKlHo1ymNUfWhydyWU9S`);
            }}
          >
            <Item bgImg={EventsBanner3}></Item>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            height: '200px',
          }}
          onClick={() => {
            navigate(`/event-type/X9sbwrmEPmrFYndlMtZa`);
          }}
        >
          <Item bgImg={EventsBanner4}></Item>
        </Grid>
        <Grid
          item
          xs={4}
          onClick={() => {
            navigate(`/event-type/2wF0F0O1mlQIOAtAjq86`);
          }}
        >
          <Item bgImg={EventsBanner5}></Item>
        </Grid>
        <Grid
          item
          xs={4}
          onClick={() => {
            navigate(`/event-type/uk6nX504qZ1jo0KytSXn`);
          }}
        >
          <Item bgImg={EventsBanner6}></Item>
        </Grid>
      </Grid>
    </Box>
  );
}
