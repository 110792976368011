import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { SnackbarProvider } from 'notistack';

const App = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  // useEffect(() => {
  //   console.clear();
  // }, [window.location.href]);
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider autoHideDuration={6000}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />
          {routing}
        </RTL>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
