import React, { useState, useEffect } from 'react';

const ImagePreview = ({ file }) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file) {
      if (typeof file === 'string') {
        // If file is a URL
        setPreview(file);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setPreview(null);
    }
  }, [file]);

  if (!preview) return null;

  return (
    <img
      src={preview}
      alt="Preview"
      style={{ width: 'auto', height: '100px', marginTop: '10px' }}
    />
  );
};

export default ImagePreview;
