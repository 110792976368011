import { Box, Card, Typography, Grid, Button } from '@mui/material';
import React from 'react';

import { useTranslation } from 'react-i18next';

export default function AnalyticalData({ eventData }) {
  const { t } = useTranslation();
  // Duplicate the eventData to ensure seamless looping
  const extendedEventData = [...eventData, ...eventData];

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        mt: 5,
        mb: 3,
        p: 2,
        backgroundColor: '#2A2358',
      }}
    >
      <Box>
        <Typography variant="h1" sx={{ color: '#fff', fontWeight: '700', paddingY: '2%' }}>
          {t('analytics')}
        </Typography>
      </Box>

      <Box>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box mx={1}>
              <img
                className="analytic-logo img-fluid"
                src="/asset/logo/partner.png"
                alt="partner"
              />
            </Box>

            <Box mx={1}>
              <Typography variant="h3" sx={{ color: '#C9FFF5', fontWeight: '700' }}>
                {t('total_partners_onboarded')}
              </Typography>
            </Box>
            <Box mx={1}>
              <Typography variant="h3" sx={{ color: '#fff', fontWeight: '700' }}>
                167
              </Typography>
            </Box>
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box mx={1}>
              <img className="analytic-logo img-fluid" src="/asset/logo/media.png" alt="media" />
            </Box>

            <Box mx={1}>
              <Typography variant="h3" sx={{ color: '#C9FFF5', fontWeight: '700' }}>
                {t('total_media')}
              </Typography>
            </Box>
            <Box mx={1}>
              <Typography variant="h3" sx={{ color: '#fff', fontWeight: '700' }}>
                5,097,567
              </Typography>
            </Box>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box mx={1}>
              <img
                className="analytic-logo img-fluid"
                src="/asset/logo/garbage.png"
                alt="garbage"
              />
            </Box>
            <Box mx={1}>
              <Typography variant="h3" sx={{ color: '#C9FFF5', fontWeight: '700' }}>
                {t('total_garbage_cleaned')}
              </Typography>
            </Box>
            <Box mx={1}>
              <Typography variant="h3" sx={{ color: '#fff', fontWeight: '700' }}>
                235t
              </Typography>
            </Box>
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box mx={1}>
              <img
                className="analytic-logo img-fluid"
                src="/asset/logo/volunteers.png"
                alt="volunteers"
              />
            </Box>
            <Box mx={1}>
              <Typography variant="h3" sx={{ color: '#C9FFF5', fontWeight: '700' }}>
                {t('total_volunteers')}
              </Typography>
            </Box>
            <Box mx={1}>
              <Typography variant="h3" sx={{ color: '#fff', fontWeight: '700' }}>
                567
              </Typography>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
      <Box
        sx={{
          mx: 5,
          px: 5,
          mt: 4,
          mb: 3,
          display: 'flex',
          overflow: 'hidden', // Hide overflow to create the marquee effect
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            animation: 'marquee 50s linear infinite', // Apply the animation
            width: 'max-content', // Ensure the width is calculated based on content
          }}
        >
          {extendedEventData.map((data, index) => (
            <img src={data.img} alt="target" />
          ))}
        </Box>
        <style jsx>{`
          @keyframes marquee {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        `}</style>
      </Box>
      {/* <Box>
        <Button className="contribute_btn" onClick={() => console.log('View More')}>
          {t('contribute')}
        </Button>
      </Box> */}
    </Box>
  );
}
