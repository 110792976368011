import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import Logo from '../../../assets/images/logos/logo.png';
import Logo1 from '../../../assets/images/logos/logo1.png';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import firebase from '../../../firebase';
import { updateProfile, updateRole } from '../../../redux/profile/action';

const LogoIcon = () => {
  return (
    <Link underline="none" to="/">
      <img src={Logo} style={{ height: '50px' }} alt="LOGO" />
    </Link>
  );
};

const Header = ({ sx }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('en');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const profileReducer = useSelector((state) => state.ProfileReducer);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(updateRole({ info: {} }));
    dispatch(updateProfile({ logged_in: false, info: {}, token: '' }));
    firebase.auth().signOut();
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const languages = [
    { code: 'en', label: 'English' },
    { code: 'hi', label: 'हिन्दी' },
  ];

  const menuItems = (
    <>
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Typography
          color="textSecondary"
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, mr: 3, my: 2 }}
        >
          {t('home')}
        </Typography>
      </Link>
      {profileReducer?.logged_in && (
        <Link to="/add-event" style={{ textDecoration: 'none' }}>
          <Typography
            color="textSecondary"
            variant="h5"
            fontWeight="500"
            sx={{ ml: 1, mr: 3, my: 2 }}
          >
            {t('dashboard')}
          </Typography>
        </Link>
      )}
      {/* <Link to="/media" style={{ textDecoration: 'none' }}>
        <Typography
          color="textSecondary"
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, mr: 3, my: 2 }}
        >
          {t('media')}
        </Typography>
      </Link> */}
      <a href="/events-map" style={{ textDecoration: 'none' }}>
        <Typography
          color="textSecondary"
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, mr: 3, my: 2 }}
        >
          {t('events')}
        </Typography>
      </a>
      <a href="/ctu-map" style={{ textDecoration: 'none' }}>
        <Typography
          color="textSecondary"
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, mr: 3, my: 2 }}
        >
          {t('adopt_ctu')}
        </Typography>
      </a>
      {profileReducer?.logged_in && (
        <Link to="/add-event?identify_ctu=true" style={{ textDecoration: 'none' }}>
          <Typography
            color="textSecondary"
            variant="h5"
            fontWeight="500"
            sx={{ ml: 1, mr: 3, my: 2 }}
          >
            {t('identify_ctu')}
          </Typography>
        </Link>
      )}
      <Link to="/partners" style={{ textDecoration: 'none' }}>
        <Typography
          color="textSecondary"
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, mr: 3, my: 2 }}
        >
          {t('partners')}
        </Typography>
      </Link>
      {!profileReducer?.logged_in && (
        <Link to="/partner-signup" style={{ textDecoration: 'none' }}>
          <Typography
            color="textSecondary"
            variant="h5"
            fontWeight="500"
            sx={{ ml: 1, mr: 3, my: 2 }}
          >
            {t('partner_signup')}
          </Typography>
        </Link>
      )}
      <Link to="/downloads" style={{ textDecoration: 'none' }}>
        <Typography
          color="textSecondary"
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, mr: 3, my: 2 }}
        >
          {t('downloads')}
        </Typography>
      </Link>
      <Link to="/sop" style={{ textDecoration: 'none' }}>
        <Typography
          color="textSecondary"
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, mr: 3, my: 2 }}
        >
          {t('sop')}
        </Typography>
      </Link>
      <Link to="/training-schedule" style={{ textDecoration: 'none' }}>
        <Typography
          color="textSecondary"
          variant="h5"
          fontWeight="500"
          sx={{ ml: 1, mr: 3, my: 2 }}
        >
          {t('training')}
        </Typography>
      </Link>
      <div className="lang_div">
        <IconButton onClick={handleClick}>
          <LanguageIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {languages.map((lang) => (
            <MenuItem
              key={lang.code}
              onClick={() => {
                changeLanguage(lang.code);
                handleClose();
              }}
            >
              {lang.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
      {!profileReducer?.logged_in ? (
        <Link to="/auth/login" style={{ textDecoration: 'none' }}>
          <Button variant="contained" color="primary" sx={{ mr: 2, my: 2 }}>
            {t('login')}
          </Button>
        </Link>
      ) : (
        <Button variant="contained" color="primary" sx={{ mr: 2, my: 2 }} onClick={handleLogout}>
          Logout
        </Button>
      )}
    </>
  );

  return (
    <>
      <AppBar
        sx={sx}
        elevation={0}
        style={{
          boxShadow: '0px 4px 33.8px 0px #00000033',
          backgroundColor: 'white',
          zIndex: 11,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar}
            sx={{ mr: 2, display: { xs: 'block', md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <LogoIcon />
          <img src={Logo1} style={{ height: '60px' }} alt="LOGO" />
          <Box flexGrow={1} />
          <Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>{menuItems}</Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={sidebarOpen} onClose={toggleSidebar}>
        <Box
          sx={{ width: 250, m: 2 }}
          role="presentation"
          onClick={toggleSidebar}
          onKeyDown={toggleSidebar}
        >
          <List>{menuItems}</List>
        </Box>
      </Drawer>
    </>
  );
};

Header.propTypes = {
  sx: PropTypes.object,
};

export default Header;
